import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Footer = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <footer
      style={{
        flexGrow: 1,
        backgroundColor: "#fff",
        padding: "20px 0",
      }}
    >
      <Container>
        <Row>
          <Col
            style={{
              display: "flex",
              flexDirection: "row", // Set row as the default direction
              alignItems: "center",
            }}
            className="socialLinks"
          >
            <a
              href="https://www.instagram.com/SKAiART.app"
              target="_blank"
              rel="noopener noreferrer"
              className="socialIcon"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://www.twitter.com/SKAiART.app"
              target="_blank"
              rel="noopener noreferrer"
              className="socialIcon"
            >
              <FaTwitter size={24} />
            </a>
            <Link to="/contact" className="contactLink">
              Contact Us
            </Link>
          </Col>

          <Col
            md={{ span: 4, offset: 4 }}
            style={
              {
                //       display: "flex",
                //       justifyContent: "flex-end",
                //       alignItems: "center",
              }
            }
          >
            <center>
              <span style={{ color: "black", fontWeight: "bold" }}>
                © {new Date().getFullYear()} SKAiART |{" "}
                <a
                  href="https://lacode.company"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    marginRight: isMobile ? 0 : "15px",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  lacode.company
                </a>
              </span>
            </center>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
