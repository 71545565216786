import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalStyle from "./styles/GlobalStyle";

import Home from "./pages/Home";

import Footer from "./components/Footer";

import { useEffect, useState } from "react";
import axios from "axios";
import Contact from "./pages/Contact";

function App() {
  const [userRole, setUserRole] = useState("");

  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/contact" element={<Contact />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
