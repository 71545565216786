import React from "react";
import "../App.css";

import { FaEnvelope } from "react-icons/fa";

function Messages() {
  return (
    <div className="Appp">
      <header className="App-headerr">
        <h1> Contact Us</h1>
        <p>We have a team of dedicated individuals ready to assist you.</p>
        <div className="contactr">
          <div className="contact-info">
            <p>You can contact our team at the following email address:</p>
          </div>
          <div>
            <a href="mailto:skaiart.app@gmail.com" className="email-link">
              <FaEnvelope size="1.5em" /> skaiart.app@gmail.com
            </a>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Messages;
