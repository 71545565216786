import React from "react";
import "./Home.css";
import { FaApple, FaGooglePlay } from "react-icons/fa";
// import Typewriter from "../components/Typewriter";
// import Typewriter from "typewriter-effect/dist/core";
// import TypeWriterEffect from "./TypeWriterEffect";

// import "./Navbar.css";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useMediaQuery } from "react-responsive";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <div className="home">
      <Container style={{ position: "relative" }}>
        {!isMobile && (
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="transparent"
            variant="white"
            style={{ position: "absolute", width: "100%" }}
          >
            <Navbar.Brand
              href="#home"
              style={{ fontWeight: "bold", color: "white" }}
            >
              SKAiART
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  href="#features"
                  style={{ fontWeight: "bold" }}
                ></Nav.Link>
              </Nav>
              <Nav
                className="justify-content-end"
                style={{ justifyContent: "space-between" }}
              >
                <Nav.Link style={{ fontWeight: "bold" }}>
                  <Link style={{ color: "white" }} to="/contact">
                    <span>Contanct Us</span>
                  </Link>
                </Nav.Link>

                {/* <Nav.Link
                  eventKey={2}
                  href="#memes"
                  style={{ fontWeight: "bold" }}
                >
                  Dank memes
                </Nav.Link> */}
                <Nav.Link
                  href="#pricing"
                  style={{
                    fontWeight: "bold",
                    pointerEvents: "none",
                    opacity: 0.5,
                  }}
                  onClick={null}
                >
                  Gif Code
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </Container>

      <header id="header" className="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-container">
                <h1 style={{ color: "white" }} className="h1-large">
                  AI-Powered Creativity
                </h1>

                <p className="typewriter-text">
                  <span style={{ fontSize: 50 }} id="js-rotating">
                    Creativity in your hands
                  </span>
                </p>

                {/* <Typewriter
                  className="p-large"
                  text="Experience amazing conversations with ChatGPT, a free AI chat application."
                /> */}
                <p style={{ color: "white" }} className="p-large">
                  Unleashing the potential of AI in artistry, generating
                  breathtaking, unique visuals with ease.
                </p>

                <div className="button-container">
                  <a
                    className="btn-solid-lg"
                    href="https://apps.apple.com/app/skaiart/id6451192281"
                  >
                    <FaApple className="icon" size={25} /> App Store
                  </a>
                  <a
                    className="btn-solid-lg"
                    href="https://play.google.com/store/apps/details?id=com.skaiart.app"
                  >
                    <FaGooglePlay className="icon" size={20} /> Google Play
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src="https://skaiart.app/mobileCopy.png"
                  alt="alternative"
                  style={{ minWidth: "90%", maxWidth: "80%", height: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>

      <Footer />
    </div>
  );
};

export default Home;
